import axios from 'axios';
import { getAuthInfo, isLoggedIn } from '../helpers/session';

const getBaseUrl = () => `${process.env.REACT_APP_API_URL}`;

const getHeaders = () => {
  let authHeaders = {};
  const baseHeaders = {
    'Content-Type': 'application/json',
    Accept: '*/*',
  };

  if (isLoggedIn()) {
    const authInfo = getAuthInfo();
    authHeaders = {
      uid: authInfo.uid,
      client: authInfo.client,
      'access-token': authInfo.accessToken,
      'token-type': 'Bearer',
    };
  }

  return { ...baseHeaders, ...authHeaders };
};

const getHeaders2 = () => {
  let authHeaders = {};
  const baseHeaders = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (isLoggedIn()) {
    const authInfo = getAuthInfo();
    authHeaders = {
      uid: authInfo.uid,
      client: authInfo.client,
      'access-token': authInfo.accessToken,
      'token-type': 'Bearer',
    };
  }

  return { ...baseHeaders, ...authHeaders };
};

export function post(url, data) {
  return axios.post(getBaseUrl() + url, data, { headers: getHeaders() });
}

export function get(url) {
  return axios.get(getBaseUrl() + url, { headers: getHeaders() });
}

export function getBase(url) {
  return axios.get(`http://localhost:3001/${url}`, { headers: getHeaders2() });
}

export async function remove(url) {
  return axios.delete(getBaseUrl() + url, { data: {}, headers: getHeaders() });
}

export async function put(url, data) {
  return axios.put(getBaseUrl() + url, data, { headers: getHeaders() });
}
