/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useState, createContext, useContext, useEffect } from 'react';
import { sortBy, concat } from 'lodash';
import * as apiService from '../../api';

const ProductContext = createContext({
  loading: false,
  categories: [],
  popularCategories: [],
  missions: [],
  segments: [],
  getProducts: (params) => {},
  getProductById: (id) => {},
  error: null,
});

function ProductProvider(props) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [popularCategories, setPopularCategories] = useState([]);
  const [missions, setMissions] = useState([]);
  const [segments, setSegments] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getCategories();
    getPopularCategories();
    getMissions();
    getSegments();
  }, []);

  const getProducts = (params = '') => {
    setLoading(true);
    const query = params ? `?${params}` : '';
    return apiService
      .get(`/products${query}`)
      .then(
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const getProductById = (id) => {
    setLoading(true);
    return apiService
      .get(`/products/${id}`)
      .then(
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const getPopularCategories = () => {
    setLoading(true);
    return apiService
      .get('/categories?popular=true')
      .then(
        (response) => {
          const { data } = response;
          setPopularCategories(data?.popular_categories);
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const getCategories = () => {
    setLoading(true);
    return apiService
      .get('/categories')
      .then(
        (response) => {
          const { data } = response;
          const categories = sortBy(data, (item) => item.id).filter(
            (item) => item.name !== 'Under Review'
          );

          const allCategories = concat(
            [
              {
                id: null,
                name: 'All Gifts',
              },
            ],
            categories
          );
          setCategories(allCategories);
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const getSegments = () => {
    setLoading(true);
    return apiService
      .get('/segments')
      .then(
        (response) => {
          const { data } = response;
          setSegments(data);
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const getMissions = () => {
    setLoading(true);
    return apiService.get('/missions').then(
      (response) => {
        const { data } = response;
        setMissions(data);
      },
      (error) => {
        setError(error.response?.data?.error);
      }
    );
  };

  return (
    <ProductContext.Provider
      value={{
        loading,
        categories,
        popularCategories,
        missions,
        segments,
        getProducts,
        getProductById,
        error,
      }}
      {...props}
    />
  );
}

const useProduct = () => useContext(ProductContext);

export { ProductProvider, useProduct };
