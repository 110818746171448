import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { UserProvider } from './context/UserContext';
import { ProductProvider } from './context/ProductContext';
import { historyHelper } from './helpers/historyHelper';
import ComingSoon from './pages/ComingSoon';
// import GiftDetail from './pages/GiftDetail';
// import Home from './pages/Home';
// import PrivateRoute from './helpers/PrivateRoute';
// import Gifts from './pages/Gifts';
// import Profile from './pages/Profile';
// import ResetPassword from './pages/ResetPassword';
// import RedirectToStore from './pages/RedirectToStore';

function App() {
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }
  return (
    <UserProvider>
      <ProductProvider>
        <div className="App">
          <Router history={historyHelper}>
            <Switch>
              <Route exact path="/coming-soon" component={ComingSoon} />
              {/* <Route exact path="/" component={Home} />
              <Route exact path="/gifts" component={Gifts} />
              <Route exact path="/gifts/:id" component={GiftDetail} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route path="/redirect/:url" component={RedirectToStore} /> */}
              <Route path="">
                <Redirect to="/coming-soon" />
              </Route>
            </Switch>
          </Router>
        </div>
      </ProductProvider>
    </UserProvider>
  );
}

export default App;
