import { cleanAuthInfo } from './session';

export const isResponseSuccess = (response) =>
  response && /20[0-9]/.test(response.status);

export const handleError = (setLoggedIn, setError, error) => {
  if (error.response.status === 401) {
    cleanAuthInfo();
    setLoggedIn(false);
  } else if (error.response.status === 500) {
    setError('An internal server error ocurred, please try again.');
  }
};
