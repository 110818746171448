import React from 'react';
import { func, string } from 'prop-types';

const Input = ({ onChange, type, className, placeholder, ...props }) => (
  <input
    type={type}
    className={className}
    placeholder={placeholder}
    onChange={onChange}
    {...props}
  />
);

Input.propTypes = {
  onChange: func.isRequired,
  className: string,
  placeholder: string,
  type: string,
};

export default Input;
