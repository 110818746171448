/* eslint-disable react/button-has-type */
import React from 'react';
import { func, string } from 'prop-types';

const Button = ({ onClick, className, text, type }) => (
  <button type={type} className={className} onClick={onClick}>
    {text}
  </button>
);
Button.propTypes = {
  onClick: func,
  className: string,
  text: string,
  type: string,
};

export default Button;
