import React, { useState } from 'react';
import gift from '../../assets/images/comingSoon/givr-coming-gift.png';
import giftMobile from '../../assets/images/comingSoon/coming-soon-gift-mobile.svg';
import logo from '../../assets/images/comingSoon/coming-soon-logo.svg';
import instagram from '../../assets/icons/instagram.svg';
import starLogo from '../../assets/images/comingSoon/coming-soon-star.svg';
import send from '../../assets/images/comingSoon/coming-soon-send.svg';
import './index.scss';
import { isResponseSuccess } from '../../helpers/apiHelpers';
import { validateEmail } from '../../helpers/validators';
import Button from '../../components/ComingSoonButton';
import Input from '../../components/ComingSoonInput';

const ComingSoon = () => {
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleClick = (event) => {
    event.preventDefault();
    setError(false);
    setErrorMessage('');

    // This line is to avoid errors with trailing whitespaces
    const trimedEmail = email.trim();
    if (validateEmail(trimedEmail)) {
      fetch(`${process.env.REACT_APP_API_URL}/potential_users`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: trimedEmail,
          name,
        }),
      })
        .then((response) => {
          if (isResponseSuccess(response)) {
            setSubmited(true);
          } else {
            setError(true);
            setErrorMessage('This email address is already registered.');
          }
        })
        .catch(() => {
          setError(true);
          setErrorMessage('Sorry something went wrong.');
        });
    } else {
      setError(true);
      setErrorMessage('Please enter a valid email address.');
    }
  };

  return (
    <div className="coming-soon">
      <img src={gift} alt="" className="coming-soon__gift" />
      <div className="coming-soon__form">
        <img
          src={giftMobile}
          alt=""
          className="coming-soon__form__gift-mobile"
        />
        <div className="coming-soon__form__title">
          <img src={logo} alt="" className="coming-soon__form__title__logo" />
          <span className="coming-soon__form__title__main">
            Get personalized gift recommendations right on time so you never
            miss another gifting deadline.
          </span>
          <span className="coming-soon__form__title__secondary">
            Coming Soon
          </span>
        </div>
        <>
          {!submited ? (
            <>
              <form
                onSubmit={handleClick}
                className="coming-soon__form__input-container"
              >
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="coming-soon__form__input-container__input"
                />
                <Input
                  placeholder="Email Address *"
                  value={email}
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  className={
                    error
                      ? 'coming-soon__form__input-container__input-error'
                      : 'coming-soon__form__input-container__input'
                  }
                />
                {error && (
                  <span className="coming-soon__form__input-container__error-message">
                    {errorMessage}
                  </span>
                )}
                <Button
                  type="submit"
                  text="Join the waitlist"
                  className="coming-soon__form__input-container__button"
                />
              </form>
              <a
                href="https://www.instagram.com/shop.givr/"
                target="_blank"
                rel="noreferrer"
                className="coming-soon__form__icon"
              >
                <img src={instagram} alt="" />
              </a>
            </>
          ) : (
            <div className="coming-soon__form__confirmation-container fadeIn">
              <img
                src={send}
                alt=""
                className="coming-soon__form__confirmation-container__image"
              />
              <span className="coming-soon__form__confirmation-container__main">
                Early bird gets the worm!
              </span>
              <span className="coming-soon__form__confirmation-container__secondary">
                We&apos;ve sent you a confirmation email to your account
              </span>
            </div>
          )}
        </>
        <div className="coming-soon__form__star">
          <img
            src={starLogo}
            alt=""
            className="coming-soon__form__star__image"
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
