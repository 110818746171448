const setAuthInfo = (accessToken, client, uid) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('client', client);
  localStorage.setItem('uid', uid);
};

const getAuthInfo = () => ({
  accessToken: localStorage.getItem('accessToken'),
  client: localStorage.getItem('client'),
  uid: localStorage.getItem('uid'),
});

const cleanAuthInfo = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('client');
  localStorage.removeItem('uid');
};

const isLoggedIn = () => Boolean(getAuthInfo().accessToken);

export { setAuthInfo, getAuthInfo, cleanAuthInfo, isLoggedIn };
